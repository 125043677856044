<template>
  <div class="fullScreenMain">
    <!-- 指派负责人开始 -->
    <!-- <div>指派负责人：</div>  -->
    <el-divider>指派负责人</el-divider>

    <div class="fr p_b">
      <el-button type="primary" icon="el-icon-plus" @click="add_user()">新增负责人</el-button>
    </div>

    <div class="mainList_content">
      <div class="mainList_content_sub">
        <el-table
          ref="multipleTable"
          row-key="id"
          border
          :data="tableData"
          :default-expand-all="true"
          tooltip-effect="dark"
          height="string">
          <el-table-column align="center" type="index" label="序号"></el-table-column>
          <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
          <el-table-column align="center" prop="deptNames" label="部门"></el-table-column>
          <el-table-column align="center" prop="postName" label="岗位"></el-table-column>
          <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
          <el-table-column align="center" width="280" prop="principalUserType" label="主次负责人">
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.principalUserType">
                <el-radio-button label="主要负责人"></el-radio-button>
                <el-radio-button label="次要负责人"></el-radio-button>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column prop="title" align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="text_Remove_Bgc" @click.stop="del(scope)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 指派负责人结束 -->
        <el-divider>商务团队分工</el-divider>
        <!-- 指派用户开始 -->
        <el-table
          ref="multipleTable2"
          border
          :data="table"
          :key="once"
          :default-expand-all="true"
          tooltip-effect="dark"
          height="string">
          <el-table-column
            align="center"
            prop="content"
            label="工作内容"
            width="150"></el-table-column>
          <el-table-column align="center" label="参与分配方">
            <template slot-scope="scope" prop="fp">
              <el-table
                border
                height="100%"
                v-show="scope.row.fp.length > 0"
                :data="scope.row.fp"
                tooltip-effect="dark">
                <el-table-column align="center" type="index" label="序号"></el-table-column>
                <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
                <el-table-column align="center" prop="deptNames" label="部门"></el-table-column>
                <el-table-column align="center" prop="postName" label="岗位"></el-table-column>
                <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
                <el-table-column prop="title" align="center" label="操作">
                  <template slot-scope="scopes">
                    <el-button
                      type="text"
                      class="text_Remove_Bgc"
                      size="small"
                      @click.stop="del2(scopes, scope)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column align="center" label="" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="text_Edit_Bgc"
                size="small"
                @click.stop="checkUser(scope)"
                >选择用户</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 指派用户结束 -->
      </div>
    </div>

    <div class="fullScreenOperation">
      <el-button type="success" plain @click="save">保存</el-button>
      <el-button type="info" @click="re">返回</el-button>
    </div>

    <EditDialog width="600px" :isShow.sync="showEdit" @submit="submit">
      <div slot="title" class="title">新增用户</div>
      <div slot="content" class="content">
        <el-tree
          ref="principalUserDataTree"
          show-checkbox
          :load="loadNode"
          :lazy="true"
          :default-expand-all="false"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          :props="{
            children: 'zones',
            label: 'label',
            isLeaf: 'leaf',
          }">
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <template v-if="data.type == 'dept'">
              <i class="iconfont iconcompany" style="color: #399ffb; margin-right: 0px"></i>
              {{ data.deptId | cascader(deptData, 'id', 'deptName') }}
            </template>
            <template v-else>
              <i class="iconfont iconbussiness-man" style="color: #399ffb; margin-right: 0px"></i>
              {{ data.loginName }}
            </template>
          </div>
        </el-tree>
      </div>
    </EditDialog>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Principaluser: () => import('./Principaluser.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      showEdit: false,
      loading: false,
      submitLoading: false,
      type: 'portrait',
      tableData: [],
      editDialogLoading: false,
      del_table: [],
      UserData: [],
      deptData: [],

      showEdit: false,
      tableData2: [], //全部用户数据
      userList: [],
      editDialogLoading: false,
      del_table2: [],
      tb_id: [],
      table: [],
      index: 0,
      content1: [
        { content: '提供有效项目信息', fp: [] },
        { content: '投标文件技术方案编制', fp: [] },
        { content: '关键沟通', fp: [] },
        { content: '其他', fp: [] },
      ],
      content2: [
        { content: '提供有效项目信息', fp: [] },
        { content: '关键沟通', fp: [] },
        { content: '有效引荐', fp: [] },
        { content: '售前与投标技术方案及咨询', fp: [] },
        { content: '商务跟进', fp: [] },
        { content: '其他', fp: [] },
      ],
      content3: [
        { content: '提供有效项目信息', fp: [] },
        { content: '关键沟通', fp: [] },
        { content: '历史商务关系', fp: [] },
        { content: '上一项目实施团队', fp: [] },
        { content: '售前与投标技术方案及咨询', fp: [] },
        { content: '商务跟进', fp: [] },
        { content: '其他', fp: [] },
      ],
      checkUserType: '',
      once: 0, //element table组件bug 需要更新div 得key值
    }
  },
  inject: ['re'],
  watch: {
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal.id) {
          this.getData()
          this.getData2()
          this.getUser()
        }
      },
    },
  },
  created() {
    this.getUserList()
  },
  methods: {
    getData() {
      this.loading = true
      this.tableData = []

      this.$api.businessManage
        .getUserAssignment(this.options.id)
        .then(res => {
          this.loading = false
          this.del_table = []
          if (res.data.principalUser == null) {
            this.tableData = []
          } else {
            let arr = res.data.principalUser
            let new_arr = []
            arr.forEach(e => {
              if (e.userType == '负责人') {
                new_arr.push(e)
              }
            })
            this.tableData = new_arr
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    add_user() {
      this.checkUserType = 1
      this.showEdit = true
    },
    del(scope) {
      this.tableData.splice(scope.$index, 1)
      if (scope.row.id) {
        this.del_table.push(scope.row.id)
      }
    },
    submit() {
      if (this.checkUserType == 1) {
        var arr = this.$refs.principalUserDataTree.getCheckedNodes(true)
        let a = {}
        arr.forEach(e => {
          a = {}
          a.userType = '负责人'
          a.businessId = this.options.id
          ;(a.staffId = e.id), (a.postName = e.postName)
          a.deptNames = e.deptNames
          ;(a.userName = e.userName),
            (a.phone = e.phone),
            (a.principalUserType = '主要负责人'),
            this.tableData.push(a)
        })
      } else if (this.checkUserType == 2) {
        var arr = this.$refs.principalUserDataTree.getCheckedNodes(true)
        let a = {}
        arr.forEach(e => {
          a = {}
          a.userType = '成员'
          a.businessId = this.options.id
          ;(a.staffId = e.id), (a.postName = e.postName)
          a.deptNames = e.deptNames
          ;(a.userName = e.userName),
            (a.phone = e.phone),
            (a.workContent = this.table[this.index].content)
          if (this.table[this.index].fp instanceof Array) {
            this.$set(this.table, this.index, this.table[this.index])
          } else {
            this.table[this.index].fp = []
            this.$set(this.table, this.index, this.table[this.index])
          }
          this.table[this.index].fp.push(a)
          this.tableData2.push(a)
        })
      }
      this.once += 1
      this.showEdit = false
    },
    save() {
      let arr = []
      for (let i = 0; i < this.tableData.length; i++) {
        const e = this.tableData[i]
        arr.push({
          id: e.id,
          staffId: e.staffId,
          businessId: this.options.id,
          userType: '负责人',
          principalUserType: e.principalUserType,
        })
      }
      for (let i = 0; i < this.tableData2.length; i++) {
        const e = this.tableData2[i]
        arr.push({
          id: e.id,
          staffId: e.staffId,
          businessId: this.options.id,
          userType: '成员',
          principalUserType: e.principalUserType,
          workContent: e.workContent,
        })
      }

      let obj = {
        userAssignmentRequst: arr,
        deleteUserAssignmentList: this.del_table,
      }

      this.$api.businessManage
        .addUserAssignment(obj)
        .then(res => {
          this.loading = false
          this.del_table = []
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    getUser() {
      this.$api.sysDept.listDept({}).then(res => {
        this.deptData = res.data
        res.data.some(r => {
          this.UserData.push({
            deptId: r.id,
            label: '',
            icon: 'iconcompany',
            type: 'dept',
          })
        })
      })
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        resolve(this.UserData)
      } else {
        if (node.data.type == 'dept') {
          this.$api.user
            .listStaffNotPage({
              deptId: node.data.deptId,
            })
            .then(res => {
              var arr = []
              res.data.some(r => {
                arr.push(
                  r.combination({
                    leaf: true,
                    deptId: node.data.deptId,
                  })
                )
              })
              resolve(arr)
            })
            .catch(err => {})
        } else {
          resolve([])
        }
      }
    },

    // 指派用户
    getData2() {
      this.loading = true
      this.$api.businessManage
        .getUserAssignment(this.options.id)
        .then(res => {
          this.loading = false
          if (this.options.businessType == 'WSQGTJBLXM') {
            this.table = this.content1.deepClone()
          } else if (this.options.businessType == 'YSQGTXKHXM')
            this.table = this.content2.deepClone()
          else {
            //YSQGTLKHXM
            this.table = this.content3.deepClone()
          }

          if (res.data.userAssignment == null) {
            this.tableData2 = []
          } else {
            let arr = res.data.userAssignment
            let new_arr = []
            arr.forEach(e => {
              this.table.forEach(i => {
                if (i.content == e.workContent) {
                  i.fp.push(e)
                }
              })

              new_arr.push(e)
            })
            this.tableData2 = new_arr
          }
          this.once += 1
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    checkUser(scope) {
      this.checkUserType = 2
      this.index = scope.$index
      this.showEdit = true
    },
    del2(scopes, scope) {
      this.table[scope.$index].fp.splice(scopes.$index, 1)
      if (scopes.row.id) {
        this.del_table.push(scopes.row.id)
      }
    },
    getUserList() {
      this.$api.sysDept.listDept({}).then(res => {
        this.deptData = res.data
        res.data.some(r => {
          this.userList.push({
            deptId: r.id,
            label: r.userName,
            icon: 'iconcompany',
            type: 'dept',
          })
        })
      })
    },
    del2(scopes, scope) {
      this.table[scope.$index].fp.splice(scopes.$index, 1)
      if (scopes.row.id) {
        this.del_table.push(scopes.row.id)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/edit.scss';
.p_t_35 {
  padding-top: 35px;
}
.p_b {
  padding-bottom: 25px;
}
.el-table {
  height: calc(100vh - 347px);
}
</style>
